import {
  ApolloClient,
  InMemoryCache,
  concat,
  createHttpLink,
  Reference,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { captureMessage, captureException } from "@sentry/nextjs";
import tokenService from "utils/TokenService";
import { GQL_PREFIX } from "utils/config";
import { showToast } from "utils/standaloneToast";
import { isServerError } from "./getServerErrorMessage";
import makeNodesLimitOffsetPagination, {
  DEFAULT_CONNECTION_KEY_ARGS,
} from "./pagination";
import {
  createListOfIdsField,
  createToReferenceForByIdField,
  createIdToReferenceField,
  createReferenceToIdField,
} from "./cacheReaders";

type HeadersContext = {
  headers?: {
    [header: string]: string | undefined;
  };
};

const authLink = setContext(
  async (_, { headers }: HeadersContext): Promise<HeadersContext> => {
    const authHeaders = await tokenService.getAuthorizationHeaders();
    return {
      headers: {
        ...authHeaders,
        ...headers,
      },
    };
  },
);

type AdminLinkContext = HeadersContext & {
  isAdminQuery?: boolean;
};

const adminLink = setContext(
  (_, { headers, isAdminQuery }: AdminLinkContext) => {
    if (!isAdminQuery) return { headers };
    return { headers: { ...headers, "X-Admin-Role": true } };
  },
);

type ActiveBrandIdContext = HeadersContext & {
  activeBrandId?: UUID;
};

const activeBrandIdContext = setContext(
  (_, { headers, activeBrandId }: ActiveBrandIdContext) => {
    if (!activeBrandId) return { headers };
    return { headers: { ...headers, "X-Active-Brand-Id": activeBrandId } };
  },
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response, forward }) => {
    const { operationName } = operation || {};

    if (networkError) {
      if (
        isServerError(networkError) &&
        networkError.statusCode === 401 &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        networkError.result?.errors?.[0]?.message === "jwt expired"
      ) {
        // If the operation only failed because the JWT is expired, try again
        // (this will cause the request to hit the auth link again, which
        // _should_ trigger a JWT refresh).
        return forward(operation);
      }
      captureException(networkError, {
        extra: { response, operationName, ...networkError },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        tags: { operation_name: operationName },
      });
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { message, extensions, path } = error || {};
        const { code, sentryId, shouldSentryCapture } = (extensions || {}) as {
          code?: string;
          sentryId?: string;
          shouldSentryCapture?: boolean;
        };
        if (!sentryId && shouldSentryCapture !== false) {
          // If the error has a sentryId, it was already captured on the server
          // with more details. We only capture the ones without here...
          captureMessage(message || "Unhandled GraphQL Error Occurred", {
            level: "error",
            extra: { error, response, operationName },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            tags: { operation_name: operationName, code },
            fingerprint: [
              "{{ default }}",
              String(operationName),
              String(path?.join(".")),
              String(code),
            ],
          });
        }

        if (code === "57014") {
          // Statement timeout
          showToast({
            title: "Request timed out",
            description:
              "A request was made that took too long to process. This should not usually " +
              "happen; our engineers have been notified. Please try again later.",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        }
      });
    }
  },
);

const persistedQueryLink = createPersistedQueryLink({
  generateHash: (document) => {
    /* eslint-disable no-underscore-dangle */
    if (
      "__meta__" in document &&
      document.__meta__ &&
      typeof document.__meta__ === "object" &&
      "hash" in document.__meta__ &&
      typeof document.__meta__.hash === "string"
    ) {
      return document.__meta__.hash;
    }
    /* eslint-enable no-underscore-dangle */
    throw new Error("GraphQL document is missing hash!");
  },
  disable: () => false,
});

const link = createHttpLink({
  uri: `${GQL_PREFIX}/graphql`,
  credentials: "omit",
});

const DEFAULT_KEY_ARGS_WITH_BRAND_ID = [
  ...DEFAULT_CONNECTION_KEY_ARGS,
  "brandId",
];

const client = new ApolloClient({
  link: concat(
    errorLink,
    concat(
      concat(concat(authLink, activeBrandIdContext), adminLink),
      concat(persistedQueryLink, link),
    ),
  ),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          retailersList: {
            read: createListOfIdsField("Retailer"),
          },
          salesPlanRetailersList: {
            // Silence warnings when removing items
            merge: false,
          },
          brand: {
            read: createToReferenceForByIdField("Brand"),
          },
          brandsList: {
            read: createListOfIdsField("Brand"),
          },
          brandRetailer: {
            read: createToReferenceForByIdField("BrandRetailer"),
          },
          brandRetailers: makeNodesLimitOffsetPagination(),
          brandRetailerSku: {
            read: createToReferenceForByIdField("BrandRetailerSku"),
          },
          brandDocument: {
            read: createToReferenceForByIdField("BrandDocument"),
          },
          brandDocuments: makeNodesLimitOffsetPagination(),
          brandUser: {
            read: createToReferenceForByIdField("BrandUser"),
          },
          brandUsers: makeNodesLimitOffsetPagination(),
          brandUsersList: {
            read: createListOfIdsField("BrandUser"),
          },
          retailer: {
            read: createToReferenceForByIdField("Retailer"),
          },
          retailers: makeNodesLimitOffsetPagination(),
          retailersForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          retailersForBrandList: createListOfIdsField("Retailer"),
          retailerLocation: {
            read: createToReferenceForByIdField("RetailerLocation"),
          },
          retailerLocations: makeNodesLimitOffsetPagination(),
          retailerLocationsList: {
            read: createListOfIdsField("RetailerLocation"),
          },
          retailerLocationWithIdentifier: {
            read: createToReferenceForByIdField("RetailerLocation"),
          },
          retailerLocationWithIdentifiers: makeNodesLimitOffsetPagination(),
          retailerLocationWithIdentifiersList: {
            read: createListOfIdsField("RetailerLocationWithIdentifier"),
          },
          retailerLocationGroupsList: {
            read: createListOfIdsField("RetailerLocationGroup"),
          },
          changeEvent: {
            read: createToReferenceForByIdField(
              "ChangeEvent",
              "eventId",
              "eventId",
            ),
          },
          changeEvents: makeNodesLimitOffsetPagination(),
          comments: makeNodesLimitOffsetPagination(),
          commentsForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          dataSourceDocument: {
            read: createToReferenceForByIdField(
              "DataSourceDocument",
              "id",
              "id",
            ),
          },
          dataSourceDocuments: makeNodesLimitOffsetPagination(),
          dataSourceProvider: {
            read: createToReferenceForByIdField(
              "DataSourceProvider",
              "name",
              "name",
            ),
          },
          dataSourceTypeGroup: {
            read: createToReferenceForByIdField(
              "DataSourceTypeGroup",
              "name",
              "name",
            ),
          },
          dataSourceType: {
            read: createToReferenceForByIdField(
              "DataSourceType",
              "name",
              "name",
            ),
          },
          dataSourceTypesList: {
            read: createListOfIdsField("DataSourceType", "name"),
          },
          dataSourceTypeBrandMapping: {
            read: createToReferenceForByIdField("DataSourceTypeBrandMapping"),
          },
          dataSourceTypeBrandMappings: makeNodesLimitOffsetPagination(),
          dataSourceTypeBrandMappingsList: {
            read: createListOfIdsField("DataSourceTypeBrandMapping"),
          },
          dataSourceTypeColumnValues: makeNodesLimitOffsetPagination(),
          dataSourceTypeColumnValuesDefinition: {
            read: createToReferenceForByIdField(
              "DataSourceTypeColumnValuesDefinition",
            ),
          },
          dataSourceTypeColumnValuesDefinitionByDataSourceTypeIdAndDefinitionType:
            {
              read: (
                existing: Reference | null | undefined,
                { args, toReference, cache },
              ) => {
                if (existing) return existing;

                const { dataSourceTypeId, definitionType } = args || {};

                if (
                  typeof dataSourceTypeId !== "string" ||
                  typeof definitionType !== "string"
                ) {
                  return;
                }

                const cached = cache.extract();

                const definitionKeys = Object.keys(cached).filter((key) =>
                  key.startsWith("DataSourceTypeColumnValuesDefinition"),
                );

                if (!definitionKeys.length) return;

                const definition = definitionKeys.find((key) => {
                  const def = cached[key];
                  return (
                    def &&
                    "dataSourceTypeId" in def &&
                    "definitionType" in def &&
                    def.dataSourceTypeId === dataSourceTypeId &&
                    def.definitionType === definitionType
                  );
                });

                if (definition) return toReference(definition);
              },
            },
          salesPlanRetailer: {
            read: createToReferenceForByIdField("SalesPlanRetailer"),
          },
          partnerBySlug: {
            read: createToReferenceForByIdField("Partner", "slug", "slug"),
          },
          pitchableAccount: {
            read: createToReferenceForByIdField(
              "PitchableAccount",
              "brandId",
              "brandId",
            ),
          },
          pitchableCampaigns: makeNodesLimitOffsetPagination(),
          pitchableCampaignRetailerContacts: makeNodesLimitOffsetPagination(),
          productLine: {
            read: createToReferenceForByIdField("ProductLine"),
          },
          productLinesList: {
            read: createListOfIdsField("ProductLine"),
          },
          documentFolderBySlug: {
            read: createToReferenceForByIdField(
              "DocumentFolder",
              "slug",
              "slug",
            ),
          },
          distributionCenter: {
            read: createToReferenceForByIdField("DistributionCenter"),
          },
          distributionCentersList: {
            read: createListOfIdsField("DistributionCenter"),
          },
          productCategoryDepartmentSubDepartment: {
            read: createToReferenceForByIdField(
              "ProductCategoryDepartmentSubDepartment",
            ),
          },
          productCategoryDepartmentSubDepartmentsList: {
            read: createListOfIdsField(
              "ProductCategoryDepartmentSubDepartment",
            ),
          },
          orderingPlatform: {
            read: createToReferenceForByIdField("OrderingPlatform"),
          },
          orderingPlatformsList: {
            read: createListOfIdsField("OrderingPlatform"),
          },
          orderingPlatformsForBrand: makeNodesLimitOffsetPagination(
            DEFAULT_KEY_ARGS_WITH_BRAND_ID,
          ),
          orderingPlatformsForBrandList: {
            read: createListOfIdsField("OrderingPlatform"),
          },
          salesPlan: {
            read: createToReferenceForByIdField("SalesPlan"),
          },
          salesPlanLiftEvents: makeNodesLimitOffsetPagination(),
          salesPlanLiftEventsList: createListOfIdsField("SalesPlanLiftEvent"),
          sku: {
            read: createToReferenceForByIdField("Sku"),
          },
          skusList: {
            read: createListOfIdsField("Sku"),
          },
          promotion: {
            read: createToReferenceForByIdField("Promotion"),
          },
          promotions: makeNodesLimitOffsetPagination(),
          promotionsList: createListOfIdsField("Promotion"),
          purchaseOrder: {
            read: createToReferenceForByIdField("PurchaseOrder"),
          },
          purchaseOrders: makeNodesLimitOffsetPagination(),
          purchaseOrdersList: createListOfIdsField("PurchaseOrder"),
          purchaseOrderSku: {
            read: createToReferenceForByIdField("PurchaseOrderSku"),
          },
          pitchableCampaignsList: createListOfIdsField("PitchableCampaign"),
          pitchableCampaignRetailerContactsList: createListOfIdsField(
            "PitchableCampaignRetailerContact",
          ),
          retailerContact: {
            read: createToReferenceForByIdField("RetailerContact"),
          },
          retailerContactEmailAddress: {
            read: createToReferenceForByIdField("RetailerContactEmailAddress"),
          },
          retailerContactPhoneNumber: {
            read: createToReferenceForByIdField("RetailerContactPhoneNumber"),
          },
          retailerChannelsList: createListOfIdsField("RetailerChannel"),
          retailerRegionsList: createListOfIdsField("RetailerRegion"),
          receivedEmailMessage: {
            read: createToReferenceForByIdField(
              "ReceivedEmailMessage",
              "emailMessageId",
              "emailMessageId",
            ),
          },
          receivedEmailMessages: makeNodesLimitOffsetPagination(),
          receivedPitchableEmailMessageTagTypesList: createListOfIdsField(
            "ReceivedPitchableEmailMessageTagType",
          ),
          user: {
            read: createToReferenceForByIdField("User"),
          },
          users: makeNodesLimitOffsetPagination(),
          usersList: {
            read: createListOfIdsField("User"),
          },
        },
      },
      BrandRetailer: {
        fields: {
          brand: {
            read: createIdToReferenceField("Brand"),
          },
          brandId: {
            read: createReferenceToIdField("brand"),
          },
          retailer: {
            read: createIdToReferenceField("Retailer"),
          },
          retailerId: {
            read: createReferenceToIdField("retailer"),
          },
        },
      },
      BrandRetailerSku: {
        fields: {
          brandRetailer: {
            read: createIdToReferenceField("BrandRetailer"),
          },
          brandRetailerId: {
            read: createReferenceToIdField("brandRetailer"),
          },
          sku: {
            read: createIdToReferenceField("Sku"),
          },
          skuId: {
            read: createReferenceToIdField("sku"),
          },
        },
      },
      BrandRetailerComment: {
        keyFields: ["commentId"],
      },
      ChangeEvent: {
        keyFields: ["eventId"],
      },
      DataSourceProvider: {
        keyFields: ["name"],
      },
      DataSourceTypeGroup: {
        keyFields: ["name"],
        fields: {
          dataSourceProvider: {
            read: createIdToReferenceField("DataSourceProvider", "name"),
          },
          dataSourceProviderId: {
            read: createReferenceToIdField("dataSourceProvider", "name"),
          },
        },
      },
      DataSourceType: {
        keyFields: ["name"],
        fields: {
          dataSourceTypeGroup: {
            read: createIdToReferenceField("DataSourceTypeGroup", "name"),
          },
          dataSourceTypeGroupId: {
            read: createReferenceToIdField("dataSourceTypeGroup", "name"),
          },
        },
      },
      SalesPlanRetailer: {
        fields: {
          salesPlanRetailerSkusList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      Partner: {
        keyFields: ["slug"],
      },
      Promotion: {
        fields: {
          retailer: {
            read: createIdToReferenceField("Retailer"),
          },
          retailerId: {
            read: createReferenceToIdField("retailer"),
          },
        },
      },
      ApprovedPitchableCampaign: {
        keyFields: ["pitchableCampaignId"],
      },
      PitchableAccount: {
        keyFields: ["brandId"],
      },
      DistributionCenter: {
        fields: {
          orderingPlatform: {
            read: createIdToReferenceField("OrderingPlatform"),
          },
        },
      },
      DocumentFolder: {
        keyFields: ["slug"],
      },
      ForwardedEmailMessage: {
        keyFields: ["emailMessageId"],
      },
      ReceivedEmailMessage: {
        keyFields: ["emailMessageId"],
      },
      ReceivedPitchableEmailMessage: {
        keyFields: ["receivedEmailMessageId"],
      },
      SalesPlan: {
        fields: {
          brand: {
            read: createIdToReferenceField("Brand"),
          },
          indyRetailerSkusBySalesPlansIdList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      Sku: {
        fields: {
          productLine: {
            read: createIdToReferenceField("ProductLine"),
          },
        },
      },
      ProductCategoryDepartmentSubDepartment: {
        fields: {
          qualifiedLabel: {
            read: (existing: unknown, { args, readField }) => {
              if (existing) return existing;
              const prefix = readField("qualifiedLabelPref");
              const categoryName = readField("categoryName");
              const separator =
                typeof args?.separator === "string" ? args?.separator : " > ";
              if (
                typeof prefix === "string" &&
                typeof categoryName === "string" &&
                prefix &&
                categoryName
              ) {
                return `${prefix}${separator}${categoryName}`;
              }
            },
          },
        },
      },
      PurchaseOrder: {
        fields: {
          purchaseOrderRevision: {
            read: createIdToReferenceField("PurchaseOrderRevision"),
          },
          purchaseOrderSkusList: {
            // Silence warnings when removing items
            merge: false,
          },
        },
      },
      RetailerLocation: {
        fields: {
          identifiers: {
            read: createIdToReferenceField(
              "RetailerLocationWithIdentifier",
              "id",
              "id",
            ),
          },
          retailer: {
            read: createIdToReferenceField("Retailer"),
          },
        },
      },
      RetailerContact: {
        fields: {
          retailerLocation: {
            read: createIdToReferenceField("RetailerLocation"),
          },
        },
      },
      RetailerContactEmailAddress: {
        fields: {
          retailerContact: {
            read: createIdToReferenceField("RetailerContact"),
          },
        },
      },
      User: {
        fields: {
          fullName: {
            read: (existing: string | undefined | null, { readField }) => {
              if (existing || existing === null) return existing;
              const firstName = readField("firstName");
              const lastName = readField("lastName");
              if (
                (typeof firstName !== "string" && firstName !== null) ||
                (typeof lastName !== "string" && lastName !== null)
              ) {
                return;
              }
              return `${firstName || ""} ${lastName || ""}`.trim();
            },
          },
        },
      },
    },
  }),
});

export default client;
