import {
  FieldReadFunction,
  Reference,
  InMemoryCache,
  isReference,
} from "@apollo/client";
import type { EntityStore } from "@apollo/client/cache";
import { camelCase } from "lodash";

export function createToReferenceForByIdField(
  typename: string,
  argName = "id",
  cacheKeyId = "id",
): FieldReadFunction {
  return (existing: unknown, { args, toReference }) => {
    if (existing || existing === null) return existing;
    if (!args?.[argName]) return;
    return toReference({
      __typename: typename,
      [cacheKeyId]: args?.[argName] as string,
    });
  };
}
type ListOfIdsArgs<IdFieldName extends string = "id"> = {
  filter?: {
    [K in IdFieldName]: { in?: string[] } | undefined;
  };
};
function getIsListOfIdsArgs<IdFieldName extends string = "id">(
  args: unknown,
  idFieldName: IdFieldName,
): args is ListOfIdsArgs<IdFieldName> {
  if (!args || typeof args !== "object") return false;

  const argNames = Object.keys(args);
  if (
    !("filter" in args) ||
    !args.filter ||
    typeof args.filter !== "object" ||
    argNames.length !== 1 ||
    argNames[0] !== "filter"
  ) {
    return false;
  }

  const filterArgNames = Object.keys(args.filter || {});
  if (
    filterArgNames.length !== 1 ||
    filterArgNames[0] !== idFieldName ||
    !(idFieldName in args.filter)
  ) {
    return false;
  }

  const idFilterArgNames = Object.keys(
    args.filter?.[idFieldName as keyof typeof args.filter] || {},
  );
  if (idFilterArgNames.length !== 1 || idFilterArgNames[0] !== "in")
    return false;

  return true;
}
export function createListOfIdsField<IdFieldName extends string = "id">(
  typename: string,
  idFieldName: IdFieldName = "id" as IdFieldName,
): FieldReadFunction {
  return (existing: unknown, { args, toReference, canRead }) => {
    if (existing || !getIsListOfIdsArgs(args, idFieldName)) return existing;

    if (!args?.filter?.[idFieldName]?.in?.length) return;

    const result = args.filter[idFieldName].in.map((id) =>
      toReference({ __typename: typename, [idFieldName]: id }),
    );

    if (result.every(canRead)) return result;

    return existing;
  };
}
export function createIdToReferenceField(
  typename: string,
  keyFieldName = "id",
  idFieldName?: string,
): FieldReadFunction<Reference> {
  return (existing, { readField, toReference }) => {
    if (existing || existing === null) return existing;
    const id = readField(idFieldName || `${camelCase(typename)}Id`);
    if (id && (typeof id === "string" || typeof id === "number")) {
      return toReference({ __typename: typename, [keyFieldName]: id });
    }
  };
}
// TODO: This shouldn't rely on private/protected methods
function getObjectFromCache(cache: InMemoryCache, cacheId: string) {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const store = cache.data as EntityStore;
  // @ts-ignore
  return store.lookup(cacheId);
  /* eslint-enable @typescript-eslint/ban-ts-comment */
}
export function createReferenceToIdField(
  referenceFieldName: string,
  keyFieldName = "id",
): FieldReadFunction<UUID | null> {
  return (existing, { cache, readField }) => {
    if (existing || existing === null) return existing;

    // We're using this indirect means of first getting the object directly from
    // the cache instead of relying on `readField` to avoid an infinite loop
    // that can be caused when both this method and `createIdToReferenceField`
    // are applied simultaneously.
    const id = readField(keyFieldName);
    const typename = readField("__typename");
    if (
      !(
        typeof id === "string" &&
        typeof typename === "string" &&
        id &&
        typename
      )
    ) {
      return;
    }

    const cacheId = cache.identify({
      __typename: typename,
      [keyFieldName]: id,
    });
    if (!cacheId) return;

    const obj = getObjectFromCache(cache, cacheId);

    if (!obj) return;

    const ref = obj[referenceFieldName];
    if (ref === null) return null;

    if (ref && isReference(ref)) {
      const idFromRef = ref.__ref?.split(":")?.[1];
      if (idFromRef) return idFromRef;
    }
  };
}
