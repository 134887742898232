/* eslint-disable import/prefer-default-export */

import { ForwardedRef, RefCallback } from "react";

export const INVALID_UUID = "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa";

export function getEstimatedByteSize(data: unknown) {
  return JSON.stringify(data).length;
}

export const DEFAULT_MAX_BYTE_SIZE = 7700;

type ChunkArrayByByteSizeReturnTuple<T> = [
  chunks: T[][],
  results: { lastChunkSize: number },
];

export type ChunkArrayByByteSizeOptions = {
  initialByteSizeOffset?: number;
  maxByteSize?: number;
};

export function chunkArrayByByteSize<T>(
  array: T[] | readonly T[],
  {
    initialByteSizeOffset = 0,
    maxByteSize = DEFAULT_MAX_BYTE_SIZE,
  }: ChunkArrayByByteSizeOptions = {},
): ChunkArrayByByteSizeReturnTuple<T> {
  let idx = 0;
  let currentChunkSize = 0;
  const chunks: T[][] = [];
  let chunkIdx = 0;

  while (idx < array.length) {
    const el = array[idx];

    if (!chunks[chunkIdx]) chunks[chunkIdx] = [];
    const currentChunk = chunks[chunkIdx];

    currentChunkSize = getEstimatedByteSize([...currentChunk, el]);

    if (
      currentChunkSize >
      maxByteSize - (chunkIdx === 0 ? initialByteSizeOffset : 0)
    ) {
      currentChunkSize = 0;
      chunkIdx += 1;
    } else {
      currentChunk.push(el);
      idx += 1;
    }
  }

  return [chunks, { lastChunkSize: currentChunkSize }];
}

export function safeNumber(value: unknown) {
  if (typeof value === "number") return value;
  if (typeof value === "string") {
    const parsed = parseFloat(value);
    if (Number.isNaN(parsed)) return null;
    return parsed;
  }
  return null;
}

export function safeDivision(numerator: unknown, denominator: unknown) {
  const numeratorNum = safeNumber(numerator);
  const denominatorNum = safeNumber(denominator);

  if (typeof numeratorNum !== "number" || typeof denominatorNum !== "number")
    return null;

  if (denominatorNum === 0) return null;

  return numeratorNum / denominatorNum;
}

export function extractUuids(
  text: string,
): [text: string | null, uuids: string[]] {
  let textSearch: string | null = text;
  const uuidRegex =
    /([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}),? */g;
  let match;
  const uuids = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = uuidRegex.exec(text)) !== null) {
    textSearch = textSearch.replace(match[0], "");
    uuids.push(match[1]);
  }
  textSearch = textSearch.trim() || null;
  return [textSearch, uuids];
}

export function combineRefs<T>(
  refA: ForwardedRef<T> | null | undefined,
  refB: ForwardedRef<T> | null | undefined,
): RefCallback<T> {
  return function refCb(component: T) {
    if (typeof refA === "function") {
      refA(component);
    } else if (refA) {
      // eslint-disable-next-line no-param-reassign
      refA.current = component;
    }

    if (typeof refB === "function") {
      refB(component);
    } else if (refB) {
      // eslint-disable-next-line no-param-reassign
      refB.current = component;
    }
  };
}
